import React from "react";
import { PageLayout } from "@components";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { VirtualFreezerHero } from "./_components/_VirtualFreezerHero/_VirtualFreezerHero";
import { StaticImage } from "gatsby-plugin-image";
import FeatureCard, { FeatureCardLayout, FeatureCardProps } from "@components/FeatureCard/FeatureCard";
import * as styles from "./virtual-freezers.module.scss";
import Margins from "@components/Margins/Margins";

const cards: FeatureCardProps[] = [
	{
		title: "A fresh take on sample storage",
		image: (
			<StaticImage
				src={"./_assets/inventory-shelf-rack.png"}
				alt="A fresh take on sample storage"
			/>
		),
		list: [
			"Leave behind the hassle of spreadsheets and embrace an intuitive, user-friendly inventory platform. Custom design a virtual freezer that matches its real-life counterpart, shelf for shelf and item for item.",
			"Personalize your storage, from shelves to cryoboxes, so that every item has its own designated space (even those bulkier ones).",
			"Simplify access with a handy bookmark feature that makes your most common-used items even easier to reach.",
		],
	},
	{
		title: "User-centric design for reagent integration",
		image: (
			<StaticImage
				src={"./_assets/box-view.png"}
				alt="User-centric design for reagent integration"
			/>
		),
		list: [
			"Our user-friendly interface makes adding reagents a breeze. Choose from preset configurations or create your own custom item types.",
			"Keep your data safe and secure with export options and boost your inventory tracking with printable barcodes.",
			"Enjoy a system that’s designed to combine maximum user comfort with advanced features.",
		],
		orientation: "right",
	},
	{
		title: "Comprehensive search tools",
		image: (
			<StaticImage
				src={"./_assets/freezers-advanced-search.png"}
				alt="Comprehensive search tools"
			/>
		),
		list: [
			"Instantly locate every sample and reagent in your inventory, anytime, thanks to our powerful search tool.",
			"Our centralized inventory system provides a single and accurate source of truth for all items.",
			"Boost your efficiency and find items even faster with our intelligent search filters.",
		],
	},
];

export default function Inventory() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "virtual-freezers",
			}}
		>
			<Margins>
				<VirtualFreezerHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<JoinInstitutionsV2
					outerContainerClassName={styles.joinInstitutions}
				/>
			</Margins>
		</PageLayout>
	);
}