import React from "react"
import { Hero } from "@components/Hero/Hero"
import FREEZER_FEATURE_VIDEO from "../../_assets/freezer-feature-video.mp4";
import * as styles from "./_VirtualFreezerHero.module.scss"

export const VirtualFreezerHero = () => {
  const props = {
		title: 'Visually dynamic solution to sample management',
		description: 'Transform the way you manage samples with an approach that’s modern, customizable and visually dynamic.',
		image: (
      <div className={styles.heroImage}>
        <video
          className={styles.videoContainer}
          muted
          autoPlay
          loop
          playsInline
          preload="auto"
        >
          <source src={FREEZER_FEATURE_VIDEO} type="video/mp4" />
        </video>
      </div>
		)
	}

	return <Hero {...props} tabletTextAlignment="center" heroTextContainerSize="small" />
}